<template>
  <div class="content">
    <app-cells position="between">
      <h1 class="title title--theme">
        <span v-if="$route.query.type === 'org'">Благодарственное письмо на организацию</span>
        <span v-else>Индивидуальное благодарственное письмо</span>
      </h1>
      <app-button
        v-if="userIsHead && (form.status.id === 1 || form.status.id === 3)"
        theme="error"
        size="small"
        @click="onShowModal"
      >
        Удалить
      </app-button>
    </app-cells>
    <app-cells position="start">
      <app-button
        size="link"
        :class="{'btn--loading': download_letter}"
        @click="onDownloadLetter"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать благодарственное письмо</span>
      </app-button>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <template v-if="$route.query.type === 'org'">
            <app-form-group label="Название организации" label-for="org" required>
              <app-input
                v-model="form.org"
                id="org"
                placeholder="Введите значение"
                :error="$v.form.org.$error"
              />
              <template #error>
                <div v-if="$v.form.org.$dirty && !$v.form.org.required">Обязательное поле</div>
              </template>
            </app-form-group>
          </template>
          <template v-else>
            <app-form-group label="Фамилия" label-for="last_name" required>
              <app-input
                v-model="form.last_name"
                id="last_name"
                placeholder="Введите значение"
                :error="$v.form.last_name.$error"
              />
              <template #error>
                <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required"></div>
              </template>
            </app-form-group>
            <app-form-group label="Имя" label-for="first_name" required>
              <app-input
                v-model="form.first_name"
                id="first_name"
                placeholder="Введите значение"
                :error="$v.form.first_name.$error"
              />
              <template #error>
                <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required"></div>
              </template>
            </app-form-group>
            <app-form-group label="Отчество" label-for="patronymic" >
              <app-input
                v-model="form.patronymic"
                id="patronymic"
                placeholder="Введите значение"
              />
            </app-form-group>
          </template>
        </template>
        <template #item-2 v-if="$route.query.type !== 'org'">
          <app-form-group label="Должность" label-for="position" >
            <app-input
              v-model="form.position"
              id="position"
              placeholder="Введите значение"
            />
          </app-form-group>
          <app-form-group label="Название организации" label-for="org" >
            <app-input
              v-model="form.org"
              id="org"
              placeholder="Введите значение"
            />
          </app-form-group>
        </template>
      </app-grid>
      <div v-if="userIsAdmin" class="color-panel color-panel--indent">
        <app-cells position="start">
          <h2 class="title">Управление</h2>
        </app-cells>
        <app-grid items="3" col="3">
          <template #item-1>
            <app-form-group label="Служебный комментарий">
              <app-textarea
                v-model="form.comment_staff"
                id="comment_staff"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-2>
            <app-form-group label="Комментарий отказа">
              <app-textarea
                v-model="form.comment_reject"
                id="comment_reject"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-3>
            <app-form-group label="Статус">
              <v-select
                v-model="form.status"
                :options="status_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
            </app-form-group>
            <app-cells>
              <app-button ref="submit" :disabled="$v.form.$error">Сохранить изменения</app-button>
            </app-cells>
          </template>
        </app-grid>
      </div>
      <app-cells v-if="!userIsAdmin && (form.status.id === 1 || form.status.id === 3)">
        <app-button
          @click="onSendFormWithChangeStatus(form.status.id)"
          type="button"
          ref="submit"
          theme="transparent"
          :disabled="$v.form.$error"
        >
          Сохранить
        </app-button>
        <app-button
          @click="onSendFormWithChangeStatus(2)"
          type="button"
          ref="submitReview"
          :disabled="$v.form.$error"
        >
          Отправить на проверку
        </app-button>
      </app-cells>
    </form>
    <modal
      name="delete-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы собираетесь удалить благодарственное письмо - ID: {{ form.id }}.
          Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onDeleteLetter" ref="submitDelete">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { deleteThankNote, getStatus, getThankNote, getThankNoteExport, putThankNote } from '@/http'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'EditThankNote',
  data() {
    return {
      download_letter: false,
      form: {},
      status_options: [],
      status_to_set: null,
    }
  },
  validations: {
    form: {
      first_name: {
        required: requiredIf(function() {
          return this.$route.query.type !== 'org'
        })
      },
      last_name: {
        required: requiredIf(function() {
          return this.$route.query.type !== 'org'
        })
      },
      org: {
        required: requiredIf(function() {
          return this.$route.query.type === 'org'
        })
      },
    }
  },
  computed: {
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    getThankNote(this.$route.params.id)
      .then(response => {
        this.form = response.data
      })
    getStatus()
      .then(response => {
        this.status_options = response.data
      })
  },
  methods: {
    onDownloadLetter() {
      this.download_letter = true
      getThankNoteExport(this.$route.params.id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Благодарственное письмо_${this.$route.params.id}.pdf`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_letter = false
        })
    },
    onDeleteLetter() {
      this.$refs.submitDelete.preload = true
      deleteThankNote(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Благодарственное письмо удалено'
          })
          this.$router.push({ name: 'thank-note-list' })
        })
    },
    onSendFormWithChangeStatus(status) {
      this.status_to_set = status
      this.onCheckForm()
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.$refs.submitReview) this.$refs.submitReview.preload = true
      if (this.$route.query.type === 'org') this.form.type = 'org'
      else this.form.type = 'individual'
      this.form.status = this.form.status.id
      if (this.status_to_set) this.form.status = this.status_to_set
      putThankNote(this.$route.params.id, this.form)
        .finally(() => {
          this.$refs.submit.preload = false
          if (this.$refs.submitReview) this.$refs.submitReview.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Благодарственное письмо изменено'
          })
          this.$router.push({ name: 'thank-note-list' })
        })
    },
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
  }
}
</script>
